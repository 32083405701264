<script context="module">
  /** アプリ起動中に地図を少なくとも一度表示したフラグ */
  let showsMapLeastOnce = false;
</script>

<script>
  import Button from "@smui/button";
  import List, { Graphic, Item, Label } from "@smui/list";
  import Menu from "@smui/menu";
  import Radio from "@smui/radio";
  import Tooltip, { Content, Wrapper } from "@smui/tooltip";
  import { format as formatDate, isToday } from "date-fns";
  import { ja as localeJa } from "date-fns/locale";
  import { escape } from "html-escaper";
  import { createEventDispatcher, getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import Map from "~/components/icons/map.svelte";
  import addressUtils from "~/libs/addressUtils";
  import {
    AddressTypeForMap,
    CONTEXT_KEY_USER,
    LostOrDamagedTypes,
  } from "~/libs/constants";
  import deliveryListUtils from "~/libs/deliveryListUtils";
  import desiredDateTime from "~/libs/desiredDateTime";
  import pageRouter from "~/libs/pageRouter";
  import { deliveryTarget } from "~/libs/stores";
  import { currentPositionStore } from "~/libs/stores";
  import { toast } from "~/libs/toast";
  import { formatTrackingNumber } from "~/libs/trackingNumberUtils";
  import ListItemMap from "~/pages/List/ListItemMap.svelte";

  const DATETIME_SPECIFICATION = "日時指定";
  const REQUEST_FOR_REDELIVERY = "再配達希望";

  /** 配達リストの荷物 @type {import("~/libs/commonTypes").DeliveryPackage} */
  export let listItem;
  /** 配達リストの配列インデックス番号 @type {number} */
  export let index;
  /** 配達リスト @type {Array<import("~/libs/commonTypes").DeliveryPackage>} */
  export let shippingList;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /**
   * Svelte Event Dispatch Function
   * @type {(type: "changeDisplayingAddress", listItem: import("~/libs/commonTypes").DeliveryPackage) => void}
   */
  const dispatch = createEventDispatcher();

  /** @type {number} */
  let displayExtraEvent;
  (() => {
    if (listItem.extraEvent && listItem.extraEvent.length > 0) {
      displayExtraEvent =
        listItem.extraEvent[listItem.extraEvent.length - 1].extraEventType;
    }
  })();

  /** Googleマップ表示フラグ */
  let showMap = false;

  /** 住所切替メニューのコンポーネントインスタンス @type {Menu} */
  let addressMenu;

  /** 現在の日時 */
  const now = new Date();

  /** 現在登録されている訂正住所 */
  let currentCorrectedAddress = listItem.correctedReceiverAddress;

  /** ラベルの一段目に表示する日時種別の分類 */
  $: dateTypes = classifyDateTypes(listItem);

  /** @type {{date: Date, time: string}} ラベルの二段目に表示する指定日時情報 */
  $: deadlineAndDesiredDate = formattedDesiredDate(listItem);

  $: if (currentCorrectedAddress !== listItem.correctedReceiverAddress) {
    // プッシュ通知等により訂正住所が更新された場合、現在地からの距離を再計算する
    recalculateDistance();
    currentCorrectedAddress = listItem.correctedReceiverAddress;
  }

  /**
   * ラベルの三段目にカウントダウンタイマーを表示するかどうか
   * @type {boolean}
   */
  let showsCountDown = false;

  /**
   * 希望日時を超過しているかどうか
   * @type {boolean}
   */
  let isOverDesiredDateTimeResult = false;

  $: if (deadlineAndDesiredDate) {
    showsCountDown =
      isToday(deadlineAndDesiredDate.date) &&
      isWithinDesiredTimeRange(deadlineAndDesiredDate.time);
    isOverDesiredDateTimeResult =
      isBeforeToday(deadlineAndDesiredDate.date) &&
      passesDesiredTime(deadlineAndDesiredDate);
  } else {
    showsCountDown = false;
    isOverDesiredDateTimeResult = false;
  }

  /**
   * ラベルの三段目にツールチップを表示するかどうか
   * @type {boolean}
   */
  let showTooltipResult = false;
  $: if (listItem.specifiedPickupDatetime !== undefined) {
    showTooltipResult =
      !!listItem.specifiedPickupDatetime.availablePickupDatetime;
  } else {
    showTooltipResult = false;
  }

  /**
   * 現在地からの距離を再計算する。
   */
  function recalculateDistance() {
    deliveryListUtils.calculateAndSetLatLon(listItem).then(() => {
      deliveryListUtils.setPackageDistance(listItem, $currentPositionStore);
      listItem = listItem;
      userContext.store();
      dispatch("changeDisplayingAddress", listItem);
    });
  }

  function goToUpdate() {
    if (Number.isInteger(listItem.receivedPushType)) {
      listItem.receivedPushType = null;
      userContext.store();
    }
    deliveryTarget.set({ ...listItem });
    pageRouter.moveToUpdate();
  }

  function formatPackageDropPlace(packageDropPlace) {
    let method = "指定なし";
    if (packageDropPlace === 0) {
      method = "置(ドア前)";
    } else if (packageDropPlace === 1) {
      method = "置(宅ボ)";
    } else if (packageDropPlace === 2) {
      method = "置(MB)";
    } else if (packageDropPlace === 3) {
      method = "置(郵便受)";
    } else if (packageDropPlace === 4) {
      method = "置(ENT)";
    } else if (packageDropPlace === 5) {
      method = "手渡し";
    }
    return method;
  }

  /**
   * ラベルの一段目に表示する日時の種別を分類する。
   * @param {import("~/libs/commonTypes").DeliveryPackage} listItem
   * @returns {string}
   */
  function classifyDateTypes(listItem) {
    if (listItem.statusText === "未") {
      // 荷物の状態が未配達の場合のみ文字列を返す

      if (listItem.specifiedPickupDatetime !== undefined) {
        // 荷受人がTrackingアプリから再配達日時を登録した場合
        if (
          listItem.specifiedPickupDatetime.availablePickupDatetime ||
          listItem.specifiedPickupDatetime.desiredRedeliveryDatetime
        ) {
          return REQUEST_FOR_REDELIVERY;
        }
      } else if (
        listItem.redeliveryContext !== undefined &&
        listItem.redeliveryContext.adjustedRedeliveryDatetime
      ) {
        // 宅配ドライバーが電話で再配達日時を調整し、Deliveryアプリに登録した場合
        return REQUEST_FOR_REDELIVERY;
      } else if (listItem.desiredDate || listItem.desiredTime) {
        // 配達希望日が登録されている場合
        return DATETIME_SPECIFICATION;
      } else {
        // 上のケースに該当せず、荷受後2日以上が経過している場合
        const inTransitAt = new Date(listItem.inTransitAt);
        const differenceTimes = now.getTime() - inTransitAt.getTime();
        const daysPassed = Math.floor(differenceTimes / (1000 * 60 * 60 * 24));
        if (daysPassed >= 2) {
          return `荷受後${daysPassed}日`;
        }
      }
    }
    return null;
  }

  /**
   * ラベルの二段目に表示する指定された日時をフォーマットする。
   * @param {import("~/libs/commonTypes").DeliveryPackage} listItem
   * @returns {{date: Date, time: string}}
   */
  function formattedDesiredDate(listItem) {
    if (
      listItem.statusText === "未" &&
      (dateTypes === DATETIME_SPECIFICATION ||
        dateTypes === REQUEST_FOR_REDELIVERY)
    ) {
      // 荷物の状態が未配達かつ、日時指定便もしくは再配達希望がある場合
      /** @type {Date} */
      let dateToCompare = null;
      /** @type {string} */
      let timeToCompare = null;

      // 初回配達の場合は配達希望日時、再配達の場合は再配達希望日時を取得する。
      const calculatedDateAndTime = desiredDateTime.resolve(listItem);

      if (calculatedDateAndTime.length === 1) {
        dateToCompare = calculatedDateAndTime[0]?.date
          ? new Date(calculatedDateAndTime[0]?.date)
          : null;
        timeToCompare = calculatedDateAndTime[0]?.timeFrame ?? null;
      } else if (calculatedDateAndTime.length > 1) {
        // 荷受人が複数の時間帯を登録した場合
        let nearestDate = null;
        let nearestTime = null;

        // 本日00:00:000を取得
        const endOfToday = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          0,
          0,
          0,
          0,
        );

        // 最も現在時刻に近く、かつ未来の日時を取得
        for (
          let j = 0;
          j < listItem.specifiedPickupDatetime.availablePickupDatetime.length;
          j++
        ) {
          const pickupDate = new Date(
            listItem.specifiedPickupDatetime.availablePickupDatetime[j].date,
          );
          const pickupTime =
            listItem.specifiedPickupDatetime.availablePickupDatetime[j]
              .timeFrame;

          if (
            pickupTime != "0000" &&
            new Date(pickupDate).setHours(
              Number(pickupTime.substring(2, 4).replace(/^0/, "")),
              0,
              0,
              0,
            ) >= endOfToday.getTime()
          ) {
            // 時間が指定されている、かつ本日00:00時点より希望日時が後の場合
            if (nearestDate === null || pickupDate < nearestDate) {
              // nearestDateがnullもしくはpickupDateがnearestDateよりも小さい場合に値を更新
              nearestDate = pickupDate;
              nearestTime = pickupTime;
            }
          }
        }
        dateToCompare = nearestDate;
        timeToCompare = nearestTime;
      }

      return { date: dateToCompare, time: timeToCompare };
    }
    return null;
  }

  /**
   * 指定された時間帯が現在時刻を過ぎているかどうかを判定する。
   * @param {{date: Date, time: string}} deadlineAndDesiredDate
   * @returns {boolean} 希望日が本日かつ希望時間が現在時刻以前の場合はtrue、それ以外（希望日・希望時間が指定されていない場合を含む）はfalse
   */
  function passesDesiredTime(deadlineAndDesiredDate) {
    if (deadlineAndDesiredDate) {
      if (isToday(deadlineAndDesiredDate.date)) {
        // 希望日が本日の場合
        if (deadlineAndDesiredDate.time) {
          // 希望時間がある場合
          const currentHour = now.getHours();
          const currentMinute = now.getMinutes();
          const currentTime = parseInt(
            currentHour.toString().padStart(2, "0") +
              currentMinute.toString().padStart(2, "0"),
          );

          // 希望時間の終了時刻を比較しやすい形式に変換
          let endTime = null;
          if (deadlineAndDesiredDate.time.slice(2, 4) === "00") {
            // 時間帯の終了時刻が0時の場合は、23時59分として計算
            endTime = parseInt("2359");
          } else {
            // それ以外の場合はそのまま計算
            endTime = parseInt(deadlineAndDesiredDate.time.slice(2, 4) + "00");
          }

          // 現在の時刻が終了時刻を過ぎている場合はtrue、それ以外はfalseを返す
          return currentTime > endTime;
        } else {
          // 希望時間が指定されていない場合はfalseを返す
          return false;
        }
      } else {
        // 希望日が本日以外の場合はtrueを返す
        return true;
      }
    } else {
      // 希望日が指定されていない場合はfalseを返す
      return false;
    }
  }

  /**
   * 希望日が本日以前かどうかを判定する。
   * @param {string | Date} date
   * @returns {boolean} 希望日が本日以前の場合はtrue、それ以外はfalse
   */
  function isBeforeToday(date) {
    if (date) {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      // 日付のみの比較を行う (時間は無視)
      return new Date(date).setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0);
    } else {
      return false;
    }
  }

  function callForReceiver() {
    window.location.href = `tel:${listItem.receiverTel}`;
  }

  function copyAddress() {
    try {
      let address;
      if (listItem.addressForMap === AddressTypeForMap.INPUTTED) {
        address = listItem.enteredAddress;
      } else if (
        listItem.addressForMap === AddressTypeForMap.CORRECTED &&
        listItem.correctedReceiverAddress
      ) {
        address = listItem.correctedReceiverAddress;
      } else {
        address = listItem.receiverAddress1;
        if (listItem.receiverAddress2) {
          address += " " + listItem.receiverAddress2;
        }
      }
      navigator.clipboard.writeText(address);
    } catch (error) {
      console.error(error); // use non-logger explicitly
      alert("住所をコピーできませんでした。");
    }
  }

  /**
   * 配達リストから本荷物を削除する。
   */
  function removeOwnFromDeliveryList() {
    const newDeliveryList = shippingList.filter((item) => item !== listItem);
    shippingList = newDeliveryList;

    userContext.deliveryList =
      newDeliveryList.length > 0 ? newDeliveryList : undefined;
    userContext.store();
  }

  /**
   * 指定日時に合わせてラベルに表示する情報を変更する。
   * @param {{date: Date, time: string}} deadlineAndDesiredDate
   * @returns {string}
   */
  function updateDesiredTimeNoticeLabel(deadlineAndDesiredDate) {
    if (listItem.statusText === "未") {
      // 荷物の状態が未配達の場合
      if (deadlineAndDesiredDate.date) {
        // 日にちが指定されている場合
        let day = "";

        if (isToday(deadlineAndDesiredDate.date)) {
          // 希望日が本日の場合、「本日」と表示する
          day = "本日";
        } else {
          // 希望日が本日ではない場合、日付と曜日を出す
          day = formatDate(deadlineAndDesiredDate.date, "M/d(E)", {
            locale: localeJa,
          });
        }

        if (
          isToday(deadlineAndDesiredDate.date) &&
          !isOverDesiredDateTimeResult
        ) {
          // 希望日が本日、かつ配達希望時間を過ぎていない場合のみ時間を表示する
          if (
            deadlineAndDesiredDate.time === "0000" ||
            !deadlineAndDesiredDate.time
          ) {
            // 時間帯が指定されていない場合
            return day + "中";
          } else if (parseInt(deadlineAndDesiredDate.time?.slice(2, 4)) == 0) {
            // 時間帯の終了時刻が0時の場合は省略する
            return (
              day + (parseInt(deadlineAndDesiredDate.time.slice(0, 2)) + "時~")
            );
          } else if (deadlineAndDesiredDate.time) {
            // 時間帯の開始・終了がそれぞれ指定されている場合
            return (
              day +
              (parseInt(deadlineAndDesiredDate.time.slice(0, 2)) +
                "~" +
                parseInt(deadlineAndDesiredDate.time.slice(2, 4)) +
                "時")
            );
          }
        } else {
          // 希望日が本日ではない場合は日付の情報のみ返す
          return day;
        }
      } else if (deadlineAndDesiredDate.time) {
        // 時間のみ指定されている場合
        if (parseInt(deadlineAndDesiredDate.time.slice(2, 4)) == 0) {
          // 時間帯の終了時刻が0時の場合は省略する
          return parseInt(deadlineAndDesiredDate.time.slice(0, 2)) + "時~";
        } else {
          // 時間帯の開始・終了がそれぞれ指定されている場合
          return (
            parseInt(deadlineAndDesiredDate.time.slice(0, 2)) +
            "~" +
            parseInt(deadlineAndDesiredDate.time.slice(2, 4)) +
            "時"
          );
        }
      }
    }
    return "";
  }

  /**
   * 指定時間までの残り時間を計算する。
   * @param {string} time
   * @returns {string | void} // FIXME: 非常に分かりづらい戻り値の型（設計バグ）
   */
  function countDownToDesiredTime(time) {
    if (time) {
      // 希望時間がある場合
      const now = new Date();
      // 現在時刻を比較しやすい形式に変換
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      // 希望時間の終了時刻を比較しやすい形式に変換
      const targetHour = parseInt(time.slice(2, 4));
      const targetMinute = 0;

      // 残り時間を計算
      let remainingHour = targetHour - currentHour;
      let remainingMinute = targetMinute - currentMinute;
      if (remainingMinute < 0) {
        remainingHour -= 1;
        remainingMinute += 60;
      }

      return `${remainingHour.toString().padStart(2, "0")}:${remainingMinute
        .toString()
        .padStart(2, "0")}`;
    }
  }

  /**
   * 配達希望時間の範囲内に現在時刻が含まれているかどうかを判定する。
   * @param {string} time - 配達希望時間
   * @returns {boolean}
   */
  function isWithinDesiredTimeRange(time) {
    if (time) {
      // 希望時間がある場合
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      const currentTime = parseInt(
        currentHour.toString().padStart(2, "0") +
          currentMinute.toString().padStart(2, "0"),
      );

      // 希望時間の開始時刻を比較しやすい形式に変換
      const startTime = parseInt(time.slice(0, 2) + "00");
      // 希望時間の終了時刻を比較しやすい形式に変換
      const endTime = parseInt(time.slice(2, 4) + "00");

      // 現在の時刻が希望時間の範囲内に含まれているかどうかを判定
      return currentTime >= startTime && currentTime <= endTime;
    } else {
      // 希望時間が指定されていない場合はfalseを返す
      return false;
    }
  }
</script>

<div
  id={listItem.trackingNumber}
  class="listRow"
  tabindex="0"
  role="button"
  on:click={goToUpdate}
  on:keydown={(event) => {
    if (event.key === "Enter") {
      goToUpdate();
    }
  }}
>
  <div class="leftColumn">
    {#if Number.isInteger(listItem.receivedPushType)}
      <div class="pushLabel blinking">
        <span>
          <!-- ja.jsonに定義された文言しか埋め込まれないためエスケープ処理不要 -->
          {@html $_(
            `pages.List.receivedPushType.${listItem.receivedPushType}`,
          )}</span
        >
      </div>
    {/if}
    <p class="sequenceNumber">{index + 1}</p>

    {#if listItem.statusText != "済" && !listItem.unacquired}
      <p class="distance">{listItem.distance ?? "算出不可"}</p>
      {#if listItem.statusText === "不"}
        {#if Number.isInteger(listItem.returnStatus)}
          <p class="extraEventLabel">
            {$_("pages.List.returnLabel")}
          </p>
        {:else if displayExtraEvent != undefined}
          <p class="extraEventLabel">
            {$_("pages.List.extraEventTypeLabel." + displayExtraEvent)}
            {#if displayExtraEvent === 2 && Number.isInteger(listItem.lostOrDamaged)}
              <br />({listItem.lostOrDamaged === LostOrDamagedTypes.LOST
                ? "紛失"
                : "損壊"})
            {/if}
          </p>
        {/if}
      {/if}
      {#if listItem.isRegisteredHandover}
        <p class="extraEventLabel">引継ぎ<br />待ち</p>
      {/if}
    {/if}
    {#if listItem.statusText === "未" && Number.isInteger(listItem.cashOnDeliveryAmount)}
      <div class="cashOndeliveryLabel">
        <span>代引き指定</span>
      </div>
    {/if}
    {#if listItem.numberOfPackages > 1}
      <!--複数個口の場合-->
      <div class="multipleLabel">
        <span>(複数){listItem.numberOfPackages}個</span>
      </div>
    {/if}
  </div>

  <div class="centerColumn">
    <p class="trackingNumber">
      {formatTrackingNumber(listItem.trackingNumber)}
    </p>
    {#if !listItem.unacquired}
      <p class="receiverAddress">
        {#if listItem.addressForMap === AddressTypeForMap.INPUTTED && listItem.enteredAddress}
          <!-- 2: 宅配ドライバーが手入力した住所 -->
          <span>{addressUtils.trimPrefecture(listItem.enteredAddress)}</span>
        {:else if listItem.addressForMap === AddressTypeForMap.CORRECTED && listItem.correctedReceiverAddress}
          <!-- 3: 訂正住所 -->
          <span class="highlight">
            <span
              >{addressUtils.trimPrefecture(
                listItem.correctedReceiverAddress,
              )}</span
            >
            <span class="note">※訂正された住所</span>
          </span>
        {:else}
          <!-- 1: 登録住所 -->
          <span>{addressUtils.trimPrefecture(listItem.receiverAddress1)}</span>
          {#if listItem.receiverAddress2}
            <span>{listItem.receiverAddress2}</span>
          {/if}
        {/if}
      </p>
      <p class="receiverName">
        {listItem.receiverName}
      </p>
    {/if}
    <div class="deliveryMethod highlightContent">
      <span class="material-icons">perm_identity</span>
      <span>受渡方法：{formatPackageDropPlace(listItem.packageDropPlace)}</span>
    </div>
    {#if listItem.cashOnDeliveryAmount}
      <div class="cashOnDelivery highlightContent">
        <span class="material-icons">currency_yen</span>
        <span>代引き：{listItem.cashOnDeliveryAmount.toLocaleString()}円</span>
      </div>
    {/if}
    {#if listItem.deliveryBoxNumber || listItem.deliveryBoxPin}
      <div class="lockerInfo highlightContent">
        <span class="material-icons">search</span
        >ボックス番号：{listItem.deliveryBoxNumber ??
          "なし"}、暗証番号：{listItem.deliveryBoxPin ?? "なし"}
      </div>
    {/if}

    {#if !listItem.unacquired}
      <div class="toolButtonArea">
        <button class="telButton" on:click|stopPropagation={callForReceiver}>
          <span class="material-icons">call</span><span>電話をかける</span>
        </button>

        <Wrapper rich>
          <button
            class="copyAddressButton"
            on:click|stopPropagation={copyAddress}
          >
            <span class="material-icons">content_copy</span>
            <span>住所をコピー</span>
          </button>
          <Tooltip
            style="top: unset; bottom: 26px; transform: translateX(calc(-50% - 42px));"
          >
            <Content>住所をクリップボードにコピーしました</Content>
          </Tooltip>
        </Wrapper>
      </div>
    {/if}
  </div>

  {#if !listItem.unacquired}
    <!-- ラベルの三段目が存在するときに限りリストの右エリアの位置を下げる -->
    <!-- 住所切替ボタンの有無で下げるpx数を変更する -->
    <div
      class="rightColumn"
      style="margin-top: {showsCountDown ||
      (isOverDesiredDateTimeResult && deadlineAndDesiredDate?.time) ||
      showTooltipResult
        ? listItem.correctedReceiverAddress || listItem.enteredAddress
          ? '40px'
          : '16px'
        : listItem.correctedReceiverAddress || listItem.enteredAddress
          ? '30px'
          : '0px'}"
    >
      {#if listItem.statusText === "未" && dateTypes}
        <div class="deadlineNoticeLabel">
          {#if dateTypes === DATETIME_SPECIFICATION || dateTypes === REQUEST_FOR_REDELIVERY}
            <div class="blackBgLabel">
              <p class="labelHeading">{dateTypes}</p>
              <div
                class="desiredTimeContainer"
                class:overdue={isBeforeToday(deadlineAndDesiredDate.date) &&
                  passesDesiredTime(deadlineAndDesiredDate)}
              >
                {#if !showTooltipResult}
                  <p
                    style={isOverDesiredDateTimeResult
                      ? "margin-top: -2px"
                      : ""}
                  >
                    {#if isOverDesiredDateTimeResult}
                      <span
                        class="material-icons"
                        style="font-size: 11px; position: relative; top: 1px"
                        >warning</span
                      >
                    {/if}
                    {updateDesiredTimeNoticeLabel(deadlineAndDesiredDate)}
                  </p>
                  {#if showsCountDown}
                    <!-- IF 希望日が本日かつ希望時間帯の範囲内に現在時刻が含まれているとき -->
                    <div class="countDownTextContainer">
                      <span class="material-icons"> hourglass_top </span>
                      <p style="margin-top: 1px">
                        {countDownToDesiredTime(deadlineAndDesiredDate.time)}
                      </p>
                    </div>
                  {/if}
                {:else}
                  <!-- IF 荷受人がTrackingアプリから複数の時間帯を登録しているとき -->
                  <div class="pickupDatetimeContainer">
                    <Wrapper rich>
                      <Button
                        color="secondary"
                        class="showPickupDatetimeButton"
                        on:click={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <p class="textLabel">
                          表示する<br />
                          <span>(複数時間帯)</span>
                        </p>
                      </Button>
                      <Tooltip
                        style="bottom: unset; right: unset; transform: translateX(-50%);"
                      >
                        <Content>
                          <p class="pickupDatetimeTitle">【受取可能日時】</p>
                          {#each listItem.specifiedPickupDatetime?.availablePickupDatetime as pickupDatetime}
                            <p>
                              {formatDate(pickupDatetime.date, "M/d(E)", {
                                locale: localeJa,
                              })}
                              {#if pickupDatetime.timeFrame && pickupDatetime.timeFrame.slice(0, 2) === "00"}
                                なし
                              {:else if pickupDatetime.timeFrame.slice(0, 1) === "0"}
                                {pickupDatetime.timeFrame.slice(1, 2)}時以降
                              {:else if pickupDatetime.timeFrame.slice(0, 1) != "0"}
                                {pickupDatetime.timeFrame.slice(0, 2)}時以降
                              {/if}
                            </p>
                          {/each}
                        </Content>
                      </Tooltip>
                    </Wrapper>
                  </div>
                {/if}
              </div>
            </div>
          {:else}
            <div class="blackLabel">
              <p>{dateTypes}</p>
            </div>
          {/if}
        </div>
      {/if}
      <button
        class="showMapButton"
        on:click|stopPropagation={() => {
          showMap = !showMap;
          if (showMap && !showsMapLeastOnce) {
            showsMapLeastOnce = true;
            setTimeout(() => {
              toast.info(
                "地図左上の「拡大地図を表示」をタップするとGoogleマップでナビが可能です。",
                { duration: 9000 },
              );
            }, 500);
          }
        }}
      >
        <Map />
      </button>
      {#if listItem.correctedReceiverAddress || listItem.enteredAddress}
        <div class="switchAddressArea">
          <button
            class="switchAddressButton"
            on:click|stopPropagation={() => {
              addressMenu.setOpen(true);
            }}
          >
            <span class="material-icons md-dark md-18">sync_alt</span>
            <span>切替</span>
          </button>

          <Menu
            bind:this={addressMenu}
            anchorCorner="BOTTOM_LEFT"
            on:SMUIList:action={() => {
              recalculateDistance();
            }}
            on:click$stopPropagation
          >
            <List class="addressMenuList" radioList>
              {#if listItem.correctedReceiverAddress}
                <Item>
                  <Graphic>
                    <Radio
                      bind:group={listItem.addressForMap}
                      value={AddressTypeForMap.CORRECTED}
                    />
                  </Graphic>
                  <Label>訂正された住所</Label>
                </Item>
              {/if}
              <Item>
                <Graphic>
                  <Radio
                    bind:group={listItem.addressForMap}
                    value={AddressTypeForMap.REGISTERED}
                  />
                </Graphic>
                <Label>登録住所</Label>
              </Item>
              {#if listItem.enteredAddress}
                <Item>
                  <Graphic>
                    <Radio
                      bind:group={listItem.addressForMap}
                      value={AddressTypeForMap.INPUTTED}
                    />
                  </Graphic>
                  <Label>自分が手入力した住所</Label>
                </Item>
              {/if}
            </List>
          </Menu>
        </div>
      {/if}

      {#if listItem.statusText == "済"}
        <button
          class="removeOwnButton"
          on:click|stopPropagation={removeOwnFromDeliveryList}
        >
          <span class="material-icons">delete_sweep</span>
        </button>
      {/if}
    </div>
  {/if}
</div>
{#if listItem.shippingKnowledge || listItem.personalMemo}
  <div class="knowledgeArea">
    <div class="knowledgeGroup">
      {#if listItem.personalMemo}
        <p class="knowledgeMessage">
          {@html escape(listItem.personalMemo).replace(/\n/g, "<br />")}
        </p>
        <div class="knowledgeInfo">
          <p>
            <span class="material-icons knowledgeIcon">sticky_note_2</span
            >個人メモ
          </p>
        </div>
      {/if}
      {#if listItem.shippingKnowledge?.byAddress}
        {#if listItem.personalMemo}
          <div class="border" />
        {/if}
        <p class="knowledgeMessage">
          {@html escape(listItem.shippingKnowledge.byAddress.memo).replace(
            /\n/g,
            "<br />",
          )}
        </p>
        <div class="knowledgeInfo">
          <p>
            <span class="material-icons knowledgeIcon">house</span>住居(<span
              class:nameHasChanged={!listItem.receiverName.startsWith(
                listItem.shippingKnowledge.byAddress.receiverName,
              )}>{listItem.shippingKnowledge.byAddress.receiverName}様</span
            >宅)配達時のメモ
          </p>
          <p>更新日時:{listItem.shippingKnowledge.byAddress.updatedAt}</p>
          <p>
            更新者:{listItem.shippingKnowledge.byAddress.updatedBy}
          </p>
        </div>
      {/if}
      {#if listItem.shippingKnowledge?.neighborhoods}
        {#each listItem.shippingKnowledge.neighborhoods as neighborHoodKnowledge, index}
          {#if listItem.personalMemo || listItem.shippingKnowledge.byAddress || index > 0}
            <div class="border" />
          {/if}
          <p class="knowledgeMessage">
            {@html escape(neighborHoodKnowledge.memo).replace(/\n/g, "<br />")}
          </p>
          <div class="knowledgeInfo">
            <p>
              <span class="material-icons knowledgeIcon">maps_home_work</span
              >近隣({addressUtils.trimPrefecture(
                neighborHoodKnowledge.address,
              )})配達時のメモ
            </p>
            <p>更新日時:{neighborHoodKnowledge.updatedAt}</p>
            <p>
              更新者:{neighborHoodKnowledge.updatedBy}
            </p>
          </div>
        {/each}
      {/if}
    </div>
  </div>
{/if}
<div class="border" />

{#if showMap}
  {#key listItem.addressForMap}
    <ListItemMap bind:listItem on:updateInputAddress={recalculateDistance} />
  {/key}
{/if}

<style lang="scss">
  .listRow {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 6px;
    padding: 10px 6px;
  }

  .leftColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 55px;

    .pushLabel {
      padding: 4px 3px 2px;
      margin-bottom: 10px;
      background-color: #f00;
      color: #fff;
      border-radius: 2px;
      font-size: 10px;
      line-height: 11px;
      font-weight: bold;
      text-align: center;
    }

    .sequenceNumber {
      width: 35px;
      height: 35px;
      margin-left: 3px;
      background-color: #fff;
      border: 1px solid #b2b2b2;
      border-radius: 50%;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      line-height: 35px;
    }

    .distance {
      margin-top: 10px;
      font-size: 13px;
      color: #555;
      text-align: center;
    }

    .extraEventLabel {
      width: 50px;
      margin-top: 6px;
      padding: 5px 0;
      background-color: rgba(0, 0, 0, 0.14);
      border-radius: 2px;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      color: rgb(68, 68, 68);
    }

    .cashOndeliveryLabel,
    .multipleLabel {
      width: 55px;
      margin-top: 7px;
      padding: 5px 0;
      background-color: #facfcf;
      color: #f00;
      border-radius: 2px;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
    }
  }

  .centerColumn {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .trackingNumber {
      font-size: 18px;
      line-height: 1.6;
    }

    .receiverAddress {
      line-height: 1.2;

      .highlight {
        padding: 3px;
        border-radius: 4px;
        background-color: #facfcf;
      }

      .note {
        font-size: 11.4px;
        color: #c80000;
      }
    }

    .receiverName {
      line-height: 1.6;
    }

    .highlightContent {
      display: flex;
      align-items: center;
      gap: 1px;
      width: fit-content;
      padding: 2px;
      font-size: 10px;
      color: #018786;
      background-color: #e7f4f4;
      border-radius: 4px;

      .material-icons {
        min-width: 14px;
        font-size: 12px;
        text-align: center;
      }
    }

    .cashOnDelivery {
      margin-top: 0.5px;
    }

    .lockerInfo {
      margin-top: 0.5px;
    }

    .toolButtonArea {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-top: 4px;

      button:active {
        background-color: #eee;
      }

      .telButton,
      .copyAddressButton {
        display: flex;
        align-items: center;
        gap: 1px;
        padding: 1px 4px;
        background-color: #fff;
        color: #018786;
        border: 1px solid #018786;
        border-radius: 4px;

        span {
          font-size: 10px;

          &.material-icons {
            font-size: 14px;
          }
        }
      }
    }
  }

  .rightColumn {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    min-width: 55px;

    .deadlineNoticeLabel {
      position: absolute;
      top: 0;
      right: 0;
      text-align: center;
      width: 67px;

      .blackBgLabel {
        font-size: 11px;
        padding: 3px 0 1px;
        background-color: #444;
      }

      .blackLabel {
        font-size: 11px;
        padding: 4px 0;
        border: 1px solid #444;
      }

      .labelHeading {
        color: #fff;
      }

      .desiredTimeContainer {
        margin: 1px 1px 0;
        padding: 4px 0;
        background-color: #fff;
        font-size: 10px;
      }
      .desiredTimeContainer.overdue {
        color: var(--mdc-theme-error);
      }

      .countDownTextContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .material-icons {
          font-size: 11px;
        }
      }

      .pickupDatetimeContainer {
        :global(.mdc-button) {
          width: 100%;
          height: fit-content;
          padding: 2px 0;
        }
      }

      .showPickupDatetimeButton {
        .textLabel {
          font-size: 10.5px;
          font-weight: bold;

          span {
            font-size: 8.5px;
            font-weight: bold;
          }
        }
      }

      .pickupDatetimeTitle {
        margin-bottom: 5px;
      }
    }

    .showMapButton {
      width: 50px;
      height: 50px;
      border: 1px solid #999;
      border-radius: 10px;
      background-color: #fff;

      &:active {
        background-color: #eee;
      }

      :global(svg) {
        width: 20px;
        vertical-align: middle;
      }
    }

    .switchAddressArea {
      position: relative;

      .switchAddressButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 26px;
        margin-top: 2px;
        padding: 4px 0;
        background-color: #fff;
        border: 1px solid #999;
        border-radius: 4px;
        font-size: 12px;
        color: #333;

        &:active {
          background-color: #eee;
        }
      }

      :global(.addressMenuList .mdc-deprecated-list-item) {
        height: 32px;
      }
      :global(.addressMenuList .mdc-deprecated-list-item__graphic) {
        margin-right: 16px;
      }
      :global(.addressMenuList .mdc-deprecated-list-item__text) {
        font-size: 14px;
      }
    }

    .removeOwnButton {
      width: 50px;
      height: 30px;
      margin-top: auto;
      border: 1px solid #999;
      border-radius: 4px;
      background-color: #fff;

      .material-icons {
        font-size: 26px;
        color: var(--mdc-theme-secondary);
        vertical-align: middle;
      }
    }
  }

  .knowledgeArea {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 10px 5px;
    text-align: left;
    background-color: #fff;
    border-radius: 10px;

    .knowledgeGroup {
      padding: 5px 7px;
      border: 1px solid #ddd;
      border-radius: 3px;
      line-height: 1.3;

      .knowledgeMessage {
        font-size: 15px;
        line-height: 1.4;
      }

      .knowledgeInfo {
        margin-top: 3px;
        font-size: 12px;
        color: #00000080;
        line-height: 15px;
      }

      .knowledgeIcon {
        font-size: 17px;
        vertical-align: middle;
        margin-bottom: 2px;
        margin-right: 2px;
      }

      .nameHasChanged {
        color: #f00;
        font-weight: bold;
      }

      .border {
        border-bottom: 1px solid #ddd;
        width: 100%;
        margin: 5px 0;
      }
    }
  }

  .border {
    border-bottom: 1px solid #ddd;
  }

  .blinking {
    -webkit-animation: blink 1.5s ease-in-out infinite alternate;
    -moz-animation: blink 1.5s ease-in-out infinite alternate;
    animation: blink 1.5s ease-in-out infinite alternate;
  }
  @-webkit-keyframes blink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes blink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes blink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>
